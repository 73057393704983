import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../../components/layout";
import { BoxResizer } from "@lucascv/box-resizer";

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const boxRef = React.useRef(null);

  return (
    <Layout location={location} title={siteTitle}>
      <div ref={boxRef} className="box-resizer-box">
        <BoxResizer boxRef={boxRef} onChange={() => {}} />
        鼠标拖动边框调整我的大小
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
